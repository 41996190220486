import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './components/home';
import About from './components/about';
import Service from './components/service';
import Blog from './components/blog';
import Contact from './components/contact';
import BlogDetails from './components/blogDetails';
import ServiceDetails from './components/serviceDetails';


function App() {
  return (
    <Routes>
      <Route path='/' element= {<Home />} />
      <Route path='/about' element= {<About />} />
      <Route path='/service' element= {<Service />} />
      <Route path='/blog' element= {<Blog />} />
      <Route path='/contact' element= {<Contact />} />
      <Route path='/blogdetails' element= {<BlogDetails />} />
      <Route path='/servicedetails' element= {<ServiceDetails />} />
    </Routes>
  );
}

export default App;
