import React from 'react'
import Footer from './Footer'
import Navbbar from './Navbbar'

function contact() {
  return (
    <div>
       <Navbbar />
        <section>
			<div class="breadcrumb-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="breadcrumb-text text-center">
								<h1>Contact Us</h1>
								<div class="breadcrumb-menu">
									<ul>
										<li><a href="index.html">home</a></li>
										<li><span>contact us</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="contact-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="section-title-s1 text-center">
								<h2>Get in Touch with Us Today - We're Here to Help!</h2>
							</div>
						</div>
					</div>
					<div class="contact-features features">			
						<div class="row no-gutters">
							<div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mx-auto">
								<div class="features-wrapper text-center">
									<div class="features-content">
										<div class="features-icon">
											<i class="las la-envelope-open"></i>
										</div>
										<h4>Our Mail Here</h4>
										<p>support@ggsolutions.tech</p>
										<p class="no-padd">info@ggsolutions.tech</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        <Footer />
    </div>
  )
}

export default contact