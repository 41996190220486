import React from 'react'
import Footer from './Footer'
import Navbbar from './Navbbar'

function blogDetails() {
  return (
    <div>
       <Navbbar />
        <section>
			<div class="breadcrumb-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-md-12 mb-30">
							<div class="breadcrumb-text text-center">
								<h1>Blog details</h1>
								<div class="breadcrumb-menu">
									<ul>
										<li><a href="index.html">home</a></li>
										<li><span>blog details</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>	
			<div class="blog-sidebar blog-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-xl-9 col-lg-9 blog-custom mb-30 mx-auto">
							<div class="row">
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="blog-wrapper">
										<div class="blog-img-details">
											<a href="blog-details.html"><img src="assets/img/blog/2.jpg" alt="" /></a>
										</div>
										<div class="blog-text">
											<h4><a href="#">Need financial help to open again your business</a></h4>
											<span>07 May 2020</span>
											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
												incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan 
												lacus vel facilisis. At vero eos et accusam et justo duo dolores et ea rebum.
											</p>
											<p class="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
												incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan 
												lacus vel facilisis. At vero eos et accusam et justo duo dolores et ea rebum.
											</p>
											<p class="pera-top mt-4">"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit", </p>
											
											<div class="blog-desc-text mt-4">
												<h3>Types of small business loans</h3>
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
											</div>
											<div class="blog-desc-text mt-4">
												<h3>Other Types  financing options</h3>
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
											</div>
											<div class="leader-content">
												<a href="#">Continue Reading</a>
											</div>
											<div class="article-share mt-4">
												<ul class="social">
													<li><p>Share this post:</p></li>
													<li>
														<a href="#" target="_blank">
															<i class="fa fa-twitter"></i>
														</a>
													</li>
													<li>
														<a href="#" target="_blank">
															<i class="fa fa-instagram"></i>
														</a>
													</li>
													<li>
														<a href="#" target="_blank">
															<i class="fa fa-facebook"></i>
														</a>
													</li>
													<li>
														<a href="#" target="_blank">
															<i class="fa fa-linkedin"></i>
														</a>
													</li>
												</ul>
											</div>
											<div class="comment-respond">
												<h4>Leave a Comment</h4>
												<form class="clearfix">
													<div class="col col-md-12">
														<input type="text" class="form-control" required="required" placeholder="First Name**" />
													</div>
													<div class="col col-md-12">
														<input type="text" class="form-control" required="required" placeholder="Last Name**" />
													</div>
													<div class="col col-md-12">
														<input type="email" class="form-control" required="required" placeholder="Email Address**" />
													</div>
													<div class="col col-xs-12">
														<textarea class="form-control" placeholder="write.."></textarea>
													</div>												
													<div class="col col-xs-12 submit-btn">
														<button type="submit">Post Comment</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>			
		</section>	
        <Footer />
    </div>
  )
}

export default blogDetails