import React, { useState } from 'react';
import './Navbar.css';

function Navbbar() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <section>
        <div className="hearder_area">
          <div id="header_sticky_2" className="mainmenu_area_2">
            <div className="container">
              <div className="row header-bg">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-10">
                  <div className="logo">
                    <a href="/">
                      <img src="assets/img/logo/muda.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-2 col-sm-2 text-right special-nav-container">
                  <div className="mainmenu_2">
                    <ul id="nav">
                      <li><a href="/">Home</a></li>
                      <li><a href="/#/about">About Us</a></li>
                      <li><a href="/#/service">Services</a></li>
                      <li><a href="/#/contact">Contact Us</a></li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-12 text-right">
                  <button className="drawer-toggle" onClick={toggleDrawer}>
                    <i className="fa fa-bars"></i>
                  </button>

                  {isDrawerOpen && (
                    <div className="custom-drawer">
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/#/about">About Us</a></li>
                        <li><a href="/#/service">Services</a></li>
                        <li><a href="/#/contact">Contact Us</a></li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Navbbar;
