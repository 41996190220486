import React from 'react'
import Footer from './Footer'
import Navbbar from './Navbbar'
import Clientcard from './clientsCard'

function index() {
  return (
    <div>
        <Navbbar />
		<section>
			<div class="slider-area">
				<div class="slider-wrapper vh d-flex " style={{ backgroundImage: 'url(assets/img/slider/bg1.jpg)' }}>
					<div class="container">
						<div class="slider-content-two text-left">
							<h1>Leaders in <span>Trade Finance & Liquidity</span><br />Management</h1>
							<p>We empower businesses to reach unparalleled growth and sustainability. With our world-class trade financing and liquidity management solutions, we ensure large enterprises and medium-sized entities (ME) maintain robust cash flows and capture global opportunities.</p>
							<a href="#">Contact now</a>
						</div>
					</div>
				</div>
			</div>
		</section>				
		<section class="services-section section-divide service-warp">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<div class="section-title-s1 text-center">
							<h2>What We Do.</h2>
						</div>
					</div>
                </div>				
				<div class="row">
					<div class="col-lg-6 col-md-6">
						<div class="single-generating d-flex mb-30">
							<div class="generating-icon">
								<i class="las la-coins"></i>
							</div>
							<div class="generating-cap">
								<h4>Trade finance</h4>
								<p>We provide financial support to our client by providing short term liquidity services that enable them to meet their operational demands.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6">
						<div class="single-generating d-flex mb-30">
							<div class="generating-icon">
								<i class="las la-chart-bar"></i>
							</div>
							<div class="generating-cap">
								<h4>Liquidity management </h4>
								<p>We enable our clients to meet their liquidity needs by providing them with quick access to liquidity in different markets at affordable rates.</p>
							</div>
						</div>
					</div>
				</div>
            </div> 
		</section>
		<Footer />
    </div>
  )
}

export default index