import React from 'react'

function Footer() {
  return (
    <div>
        <section>			
            <footer>
                <div class="footer-bootom-area pt-40 mt-40">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="copyright">
                                    <p>Copyright ©ggsolutions.tech</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="copyright">
                            {/* <p>Top House, Plot 65, Kampala Road, Uganda</p> */}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>	
        </section>
    </div>
  )
}

export default Footer