import React from 'react'
import Footer from './Footer'
import Navbbar from './Navbbar'

function serviceDetails() {
  return (
    <div>
        <Navbbar />
        <section>
			<div class="breadcrumb-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="breadcrumb-text text-center">
								<h1>Service Details</h1>
								<div class="breadcrumb-menu">
									<ul>
										<li><a href="index.html">home</a></li>
										<li><span>service-details</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>			
        <section class="service-single-section single-padding">
            <div class="container">
                <div class="row">					
					<div class="col-sm-12 col-md-12 col-md-push-4 mx-auto">					
                        <div class="service-single-content">
                            <div class="service-details">
                                <h2>Business Consulting</h2>
                                <p>"We provide financial support to our client by providing short term liquidity services that enable them to meet their operational demands.</p>
                                <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                <ul>
                                    <li><i class="fa fa-adjust"></i> Sed do eiusmod tempor incididunt ut</li>
                                    <li><i class="fa fa-adjust"></i> Labore et dolore magna aliqua</li>
                                    <li><i class="fa fa-adjust"></i> Ut enim ad minim veniam quis nostrud</li>
                                </ul>

                                <div class="img-holder">
                                    <img src="assets/img/new/img-1.jpg" alt="" />
                                </div>
                            </div>

                            <div class="service-single-tab">
                                <ul class="nav">
                                    <li class="active">
                                        <a href="#audit" data-toggle="tab">Audit</a>
                                    </li>
                                    <li>
                                        <a href="#strategy" data-toggle="tab">Strategy</a>
                                    </li>
                                    <li>
                                        <a href="#sustainability" data-toggle="tab">Sustainability</a>
                                    </li>
                                </ul>

                                <div class="tab-content">
                                    <div class="tab-pane fade in active" id="audit">
                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. eaque ipsa quae ab illo inventore.</p>
                                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                    </div>
                                    <div class="tab-pane fade" id="strategy">
                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. eaque ipsa quae ab illo inventore.</p>
                                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                    </div>
                                    <div class="tab-pane fade" id="sustainability">
                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. eaque ipsa quae ab illo inventore.</p>
                                        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>	
        <Footer />
    </div>
  )
}

export default serviceDetails