import React from 'react'
import Footer from './Footer'
import Navbbar from './Navbbar'

function blog() {
  return (
    <div>
       <Navbbar />
        <section>
			<div class="breadcrumb-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-md-12 mb-30">
							<div class="breadcrumb-text text-center">
								<h1>Our Blogs</h1>
								<div class="breadcrumb-menu">
									<ul>
										<li><a href="index.html">home</a></li>
										<li><span>blogs</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>	
			<div class="blog-sidebar blog-area section-divide item">
				<div class="container">
					<div class="row">
						<div class="col-xl-9 col-lg-9 blog-custom mb-30 mx-auto">
							<div class="row">
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="blog-wrapper mb-30">
										<div class="blog-img-details">
											<a href="blog-details.html"><img src="assets/img/blog/2.jpg" alt="" /></a>
										</div>
										<div class="blog-text">
											<h4><a href="#">Need financial help to open again your business</a></h4>
											<span>07 May 2020</span>
											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
												incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan 
												lacus vel facilisis. At vero eos et accusam et justo duo dolores et ea rebum.
											</p>
											<div class="leader-content">
												<a href="/#/blogdetails">Continue Reading</a>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="blog-wrapper mb-30">
										<div class="blog-img-details">
											<a href="#"><img src="assets/img/blog/1.jpg" alt="" /></a>
										</div>
										<div class="blog-text">
											<h4><a href="blog-details.html">Financing loans available to small businesses</a></h4>
											<span>07 May 2020</span>
											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
												incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan 
												lacus vel facilisis. At vero eos et accusam et justo duo dolores et ea rebum.
											</p>
											<div class="leader-content">
												<a href="/#/blogdetails">Continue Reading</a>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-md-none d-lg-block">
									<div class="blog-wrapper mb-30">
										<div class="blog-img-details">
											<a href="#"><img src="assets/img/blog/3.jpg" alt="" /></a>
										</div>
										<div class="blog-text">
											<h4><a href="blog-details.html">5 Ways you can prepare your business for success</a></h4>
											<span>07 May 2020</span>
											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
												incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan 
												lacus vel facilisis. At vero eos et accusam et justo duo dolores et ea rebum.
											</p>
											<div class="leader-content">
												<a href="/#/blogdetails">Continue Reading</a>
											</div>
										</div>
									</div>
								</div>
								<div class="col-xl-12">
									<div class="paginationss">
										<ul>
											<li class="active"><a href="#">1</a></li>
											<li><a href="#">2</a></li>
											<li><a href="#">3</a></li>
											<li><a href="#"><i class="fa fa-angle-right"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
		</section>
        <Footer />
    </div>
  )
}

export default blog