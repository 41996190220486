import React from 'react'
import Footer from './Footer'
import Navbbar from './Navbbar'
import ClientsCard from './clientsCard'

function about() {
  return (
    <div>
        <Navbbar />
        <section>
			<div class="breadcrumb-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-md-12 mb-30">
							<div class="breadcrumb-text text-center">
								<h1>About Us</h1>
								<div class="breadcrumb-menu">
									<ul>
										<li><a href="index.html">home</a></li>
										<li><span> about us</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="industry-section section-divide">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="section-title-s2 text-center">				
							<h2>Major Companies About us</h2>
							<p>At GG Tech, we've been at the forefront of the financial advisory and investment industry for some years. Our unwavering commitment to excellence, integrity, and innovation has made us a trusted partner for individuals, businesses, and institutions worldwide.</p>
						</div>
					</div>
				</div>
				<div class="row">
					{/* <div class="col-xl-6 col-lg-6 col-sm-12 col-md-6">
						<div class="img-holder">
							<img src="assets/img/all/52.png" alt="" />
						</div>
					</div> */}
					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
						<div class="features-grids">
							<div class="grid">
								<div class="icon">
									<i class="fi flaticon-businessman"></i>
								</div>
								<h3>Our Journey</h3>
								<p>Founded some years back, GG Tech was born out of a vision to provide exceptional financial guidance and investment solutions. Over the years, we've evolved and adapted to the ever-changing financial landscape while remaining true to our core values.</p>
							</div>
							<div class="grid">
								<div class="icon">
									<i class="fi flaticon-salary"></i>
								</div>
								<h3>Our Mission</h3>
								<p>Our mission is simple yet profound: to empower our clients to achieve their financial goals and secure their financial future. We believe that sound financial advice and strategic investments are the cornerstones of prosperity.</p>
							</div>
							<div class="grid">
								<div class="icon">
									<i class="fi flaticon-up-arrow"></i>
								</div>
								<h3>Why Choose Us</h3>
								<p>Why Choose Us: We bring expertise, a client-centric approach, innovation, global reach, and an unwavering commitment to excellence to meet your financial needs.</p>
							</div>
						</div>
					</div>
				</div>
			</div> 
		</section>

        {/* <ClientsCard /> */}
		
		{/* <div class="leader-area section-divide">
			<div class="container">
				<div class="row">						
					<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<div class="leader-wrapper mb-30">
							<div class="leader-content">
								<span>About us</span>
								<h2>We are the leader in Financial Advising and Investment services</h2>
								<p>As pioneers in the financial advising and investment industry, GG Tech stands at the forefront of innovation and excellence. With a rich history of success and a commitment to our clients' financial well-being, we are the go-to choice for individuals, businesses, and institutions seeking expert guidance. </p>
								<div class="about-features">
									<div class="about-features-item">
										<div class="about-feature-icon">
											<i class="fa fa-check"></i>
										</div>
										<div class="about-feature-text">
											<h4>Unparalleled Expertise</h4>								
										</div>
									</div>
									<div class="about-features-item">
										<div class="about-feature-icon">
											<i class="fa fa-check"></i>
										</div>
										<div class="about-feature-text">
											<h4>Tailored Client-Centric Solutions</h4>										
										</div>
									</div>
									<div class="about-features-item">
										<div class="about-feature-icon">
											<i class="fa fa-check"></i>
										</div>
										<div class="about-feature-text">
											<h4>Global Impact</h4>										
										</div>
									</div>
								</div>
								<a href="/#/contact">Hire Us</a>
							</div>
						</div>
					</div>		
					<div class="col-lg-3 ml-left">
						<div class="row">
							<div class="col-lg-12 col-md-6">
								<div class="about-cop">
									<div class="icon d-flex align-items-center"><span class="fi flaticon-graphic"></span></div>
									<div class="text media-body">
										<h3>Global Reach</h3>
									</div>
								</div>
							</div>
							<div class="col-lg-12 col-md-6">
								<div class="about-cop">
									<div class="icon"><span class="fi flaticon-analysis"></span></div>
									<div class="text media-body">
										<h3>Commitment to Excellence</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 ml-left">
						<div class="row">
							<div class="col-lg-12 col-md-6">
								<div class="about-cop about-cop-middle">
									<div class="icon"><span class="fi flaticon-coins"></span></div>
									<div class="text media-body">
										<h3>Client-Centric Approach</h3>
									</div>
								</div>
							</div>
							<div class="col-lg-12 col-md-6">
								<div class="about-cop">
									<div class="icon"><span class="fi flaticon-coins"></span></div>
									<div class="text media-body">
										<h3>Expertise</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section>
			<div class="review-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-md-12 col col-sm-12">
							<div class="section-title-s1 text-center">                          
								<h2>Our Client Review</h2>
								<p>Joseph Musoke: GG Tech transformed my financial journey. Their tailored strategies consistently yield strong returns. They genuinely care about client success.</p>
							</div>
						</div>
					</div>
                </div>
			</div>		
		</section> */}
        <Footer />
    </div>
  )
}

export default about