import React from 'react'
import Footer from './Footer'
import Navbbar from './Navbbar'

const services = [
	{
	  title: "Trade finance",
	  imageSrc: "assets/img/service/01.jpg",
	  description: "We provide financial support to our client by providing short term liquidity services that enable them to meet their operational demands.",
	},
	{
	  title: "Liquidity management",
	  imageSrc: "assets/img/service/05.jpg",
	  description: "We enable our clients to meet their liquidity needs by providing them with quick access to liquidity in different markets at affordable rates.",
	},
  ];
function service() {
  return (
    <div>
        <Navbbar />
        <section>
			<div class="breadcrumb-area section-divide">
				<div class="container">
					<div class="row">
						<div class="col-md-12 mb-30">
							<div class="breadcrumb-text text-center">
								<h1>Our Services</h1>
								<div class="breadcrumb-menu">
									<ul>
										<li><a href="index.html">home</a></li>
										<li><span>Our Services</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>		
		</section>
       <section class="services-section section-divide">
            <div class="container">
                <div class="row">
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div class="section-title-s1 text-center">
								<h2>Our Top Services</h2>
							</div>
						</div>
					</div>			
				<div class="row">
				{services.map((service, index) => (
					<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<div class="service-warp">
							<div class="service-desc mb-30">
								<h4>{service.title}</h4>
								<div class="service-warp-img">
									{/* <img src={service.imageSrc} alt="" /> */}
								</div>								
								<p>
								{service.description}
                                <div class="figure-btn">
                                    <a href="#">Read More..</a>
                                </div>
								</p>
                                						
							</div>
						</div>
					</div>
				))}
				</div>
			</div> 
		</section>
        <Footer />
    </div>
  )
}

export default service